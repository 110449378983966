.wmde-markdown {
  font-family: 'Open Sauce One', sans-serif;
  font-size: 0.875rem;
}

.custom-list-style .wmde-markdown ul {
  list-style-image: url('/assets/images/attention-icon.svg');
}

.w-md-editor-text textarea:disabled {
  background: rgba(219, 224, 231, 0.2);
}

.scroll-down-container .w-md-editor-preview
{
  display: flex;
  flex-direction: column-reverse;
}

.scroll-down-container .w-md-editor-preview ul li  strong:first-child
{
  color: #808080;
  font-weight: normal;
}