@use './fonts';
@use './colors';
@import './loading';
@import './code-element.scss';

/* custom CSS classes */
.wrapped-text {
  white-space: normal;
  word-break: break-word;
}

html,
body,
#root {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: $default-font-family;
}

html {
  font-size: 100%;
}
