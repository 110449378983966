._sidebar {
    color: white;
}

.sidebar-item {
    color: white;
    padding-left: 2;
    padding-top: 5;
}

.line {
    opacity: .2;
    border: 1px solid #FFFFFF;
    height: 0px;

    &.solid {
        opacity: 1;
    }
}

.footer-link{
    display:contents;
    color: inherit;
}

.menu-icon{
    margin: 8px 6px 3px 8px;
}

/* Centers the icon */
.MuiListItemIcon-root {
    display: flex;
    justify-content: center;
}

/* Places the text at the start and aligns the text with the MuiListItemIcon */
.MuiListItemText-root {
    display: flex;
    justify-content: flex-start;
}