/* Provide sufficient contrast against white background */
@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/all';

html,
body,
#root {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: $default-font-family;
}

html {
  font-size: 100%;
}
p {
  .small1 {
    font-size: 16px;
  }
  .small2 {
    font-size: 14px;
  }
  .small3 {
    font-size: 13px;
  }
  .tiny {
    font-size: 12px;
  }
}
.footer {
  flex-shrink: 0;
}
* {
  box-sizing: border-box;
}
